/* You can add global styles to this file, and also import other style files */
@import "firebaseui/dist/firebaseui.css";
@import "primeng/resources/themes/arya-orange/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import 'primeflex/primeflex.css';
.colorRed {
  color: var(--red-500)
}

.colorGreen {
  color: var(--green-500)
}

.colorYellow {
  color: var(--yellow-500)
}

.p-badge.p-badge-danger {
  background-color: var(--red-500)!important;
}

:root {
  --sidenav-width: 205px;  // <- already exists
  --sidenav-collapsed-width: 90px;  // <- we define a new one
}

.center-p-nav-header {
  ul {
    justify-content: center !important;
  }
}
